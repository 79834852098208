import GA4 from './GA4'

// On page load datalayer is fired
export default function onPageLoadDatalayer(
  villageName,
  brandName,
  contentGroup,
  language
) {
  GA4('dataLayer_load', {
    village_name: villageName,
    brand_name: brandName,
    content_group: contentGroup,
    language,
  })
}
