/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import { ThemeProvider } from 'styled-components'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Popup, { PopupProvider } from 'components/Popup'
import { ModalProvider } from 'components/Modal/context'

import MemorableDays from 'components/MemorableDays'
import SEO from 'components/SEO'
import globalStyle from 'styles/globalStyle'
import fonts, { fontFaceFromArray } from 'styles/fonts'
import theme from 'styles/theme'
import { getEligibleMembershipPopup } from 'utils/popup'
import onPageLoadDatalayer from './src/utils/onPageLoadDatalayer'

const COLLECTION_TITLE = 'The Bicester Collection'

const getPageProperty = (page, property) =>
  page &&
  (page[property] ||
    (page.village &&
      page.village.openingHours &&
      page.village.openingHours[property]))

/**
 * This file provide Logic to include Header and footer elements outside
 * of the page element so they dont unmount when navigating.
 * see: How to prevent layout components from unmounting
 * https://www.gatsbyjs.org/docs/layout-components
 */
export const wrapPageElement = ({ element, props }) => {
  // TODO: logic for custom font face based on locale
  const fontFace = fontFaceFromArray(fonts['base'])
  const GlobalStyle = globalStyle(fontFace)

  const pageLevel = props?.pageContext?.pageLevel
  const data = props.data
  const {
    page,
    page: { village } = {},
    villages,
    collectionPage,
    memDaysOnboarding,
    allMemorableDays: { edges: allMemorableDaysPages } = {},
    allMembershipPopUps: { edges: allMembershipPopUps = [] } = {},
    valueRetailPage,
  } = data || {}
  const hideFromSearchEngine = getPageProperty(page, 'hideFromSearchEngine')
  let title = getPageProperty(page, 'pageTitle')
  let description = getPageProperty(page, 'pageDescription')
  const translatedSlugs = props.pageContext.translatedSlugs
  const translatedServices = props.pageContext.translatedServices

  // DX-2187 For Lifestyle templates if meta title and description are not set, replace them with on-page title and description
  if (page?.__typename === 'ContentfulPageTemplateLifestyleT17') {
    title = getPageProperty(page, 'pageTitle') || getPageProperty(page, 'title')
    description =
      getPageProperty(page, 'pageDescription') ||
      getPageProperty(page, 'description')
  }

  let locale = page && page.locale
  let header = null
  let footer = null
  let villageSlug = ''
  let villageName = ''
  let allVillages = null

  switch (pageLevel) {
    case 'collection':
      if (page) {
        header = page.header
        footer = page.footer
      }

      if (collectionPage) {
        header = collectionPage.header
        footer = collectionPage.footer
      }

      allVillages = villages?.nodes

      break

    case 'village':
      if (village && village.home[0]) {
        const linksOnly = village.home[0].header?.navigation?.navItems?.filter(
          (item) => item.__typename === 'ContentfulCompLinkLnk01'
        )

        if (linksOnly) {
          village.home[0].header.navigation.navItems = linksOnly
        }
        header = village.home[0].header
        footer = village.home[0].footer
        locale = village.home[0].locale
        villageSlug = village.villageSlug
        villageName = village.name
      }

      break

    case 'services':
      if (village) {
        header = village.servicesHeader
        footer = village.home[0] && village.home[0].footer
        villageSlug = village.villageSlug
        villageName = village.name
      }

      break

    case 'value-retail':
      if (page) {
        header = page.header
        footer = page.footer
      }

      if (valueRetailPage) {
        header = valueRetailPage.header
        footer = valueRetailPage.footer
      }

      allVillages = villages?.nodes

      break

    default: // Do nothing
  }

  const revision =
    props.pageContext && props.pageContext.revision
      ? props.pageContext.revision
      : null

  const membershipPopUp = getEligibleMembershipPopup({
    edges: allMembershipPopUps,
    currentId: props.pageContext.id,
  })

  const headerProps =
    header && locale
      ? {
          languageItems: header.languageGroup
            ? header.languageGroup.languages
            : [],
          signinItems: header.signinGroup,
          locale,
          logos: header.logos,
          menuItems:
            header.navigation && header.navigation.navItems
              ? header.navigation.navItems
              : [],
          notification: header.notification,
          pageLevel,
          pathname: props.location.pathname,
          servicesPage: pageLevel === 'services',
          translatedServices,
          translatedSlugs,
          valueRetailPage: pageLevel === 'value-retail',
          villageSlug,
          villageName:
            pageLevel === 'collection'
              ? 'The Bicester Collection'
              : villageName,
          membershipPopUp,
          villageServicesEndPoint: props.pageContext.villageServicesEndPoint,
          villages: allVillages,
          hideLanguages: header.hideLanguages,
        }
      : null

  const footerProps =
    footer && locale
      ? {
          locale,
          navItems: footer.navItems,
          trademarkCopy: footer.copy.copy,
          copyright: footer.copyright,
          pathOrigin: props.location.origin,
          logo:
            footer.logos && footer.logos[0] ? footer.logos[0].code.code : '',
          socialItems: {
            instagram: footer.instagramLink,
            facebook: footer.facebookLink,
            twitter: footer.twitterLink,
            youtube: footer.youTubeLink,
            pinterest: footer.pinterestLink,
            tripadvisor: footer.tripAdvisorLink,
          },
          reduced: pageLevel === 'services',
          villageSlug,
          villageName:
            pageLevel === 'collection'
              ? 'The Bicester Collection'
              : villageName,
          valueRetailPage: pageLevel === 'value-retail',
          translatedSlugs,
          buttonPromo: {
            ...footer.buttonPromo,
            villageName:
              pageLevel === 'collection'
                ? 'The Bicester Collection'
                : villageName,
            bodyCopy:
              (footer.buttonPromo &&
                footer.buttonPromo.bodyCopy &&
                footer.buttonPromo.bodyCopy.bodyCopy) ||
              '',
          },
        }
      : null

  const SEOProps =
    locale && headerProps && headerProps.languageItems
      ? {
          title: `${title ? `${title} | ` : ''}${villageName ||
            COLLECTION_TITLE}`,
          description,
          pathname: props.location.pathname,
          locale,
          revision,
          languages: headerProps.languageItems,
          hideFromSearchEngine: hideFromSearchEngine === true,
          valueRetailPage: pageLevel === 'value-retail',
          defaultLocale: village ? village.defaultLocale : 'en',
        }
      : null

  const allMemorableDays =
    allMemorableDaysPages && allMemorableDaysPages.map(({ node }) => node)
  const memorableDaysProps = memDaysOnboarding && {
    allMemorableDays,
    locale,
    ...memDaysOnboarding,
  }

  // On page load datalayer is fired
  if (page) {
    const brandName = page.name ?? 'non brand page'
    const contentGroup =
      props?.pageContext?.pageTypeSlug || page?.pageType?.pageTypeSlug
    const language = props?.pageContext?.nodeLocale || page?.locale
    const villageNameValue =
      pageLevel === 'collection' ? 'The Bicester Collection' : villageName
    onPageLoadDatalayer(villageNameValue, brandName, contentGroup, language)
  }

  return (
    <ModalProvider>
      <PopupProvider>
        {SEOProps && <SEO {...SEOProps} />}
        <GlobalStyle />
        {headerProps && <Header {...headerProps} />}
        {element}
        {footerProps && <Footer {...footerProps} />}
        {memDaysOnboarding && (
          <MemorableDays
            onboarding={memorableDaysProps}
            location={props.location}
          />
        )}
        <Popup
          villageName={
            pageLevel === 'collection' ? 'The Bicester Collection' : villageName
          }
        />
      </PopupProvider>
    </ModalProvider>
  )
}

// Wrap root with theme provider.
export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
)
