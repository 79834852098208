// @ts-nocheck
// TOOD come back to check breakpoints

import breakpoints from './breakpoints'

export default {
  default: `@media screen and (min-width: 0px)`,
  small: `@media screen and (min-width: ${breakpoints.sm})`,
  medium: `@media screen and (min-width: ${breakpoints.md})`,
  large: `@media screen and (min-width: ${breakpoints.lg})`,
  xlarge: `@media screen and (min-width: ${breakpoints.xl})`,
  mobile: `@media screen and (max-width: ${parseInt(breakpoints.lg, 10) -
    1}px)`,
  smallOnly: `@media screen and (max-width: ${parseInt(breakpoints.md, 10) -
    1}px)`,
  ipad_portrait: `@media all and (device-width: ${breakpoints.ipad_portrait.width}) and (device-height: ${breakpoints.ipad_portrait.height}) and (orientation:portrait)`,
  ipad_landscape: `@media all and (device-width: ${breakpoints.ipad_landscape.width}) and (device-height: ${breakpoints.ipad_landscape.height}) and (orientation:landscape)`,
  landscape: `@media (orientation:landscape)`,
}
